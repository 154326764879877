import PalletComponent from "../../components/pallet/pallet-component";
import Container from "react-bootstrap/Container";
import { useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

const PalletPage = () => {
  const { palletNumber } = useParams();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/pallets");
  };

  return (
    <Container>
      {palletNumber ? (
        <PalletComponent palletNumber={palletNumber} />
      ) : (
        <>
          <h1>Create New Pallet</h1>
          <PalletComponent palletNumber="new" />
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </>
      )}
    </Container>
  );
};

export default PalletPage;
