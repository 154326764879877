import React, { useState, useEffect, useContext } from "react";
import { PalletState } from "./types";
import { useForm, Controller } from "react-hook-form";
import { UserAuthenticatedContext } from "../profile";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import Loading from "../loading";
import { useNavigate } from "react-router-dom";
import DespatchModal from "./despatch-modal";
import Modal from "react-bootstrap/Modal";
import { formatDateTimeFromTimestamp } from "../../helpers/datetime";

const createPalletWorker = createWorkerFactory(
  () => import("../../workers/pallet")
);

export interface PalletComponentProps {
  palletNumber: string;
}

const PalletComponent = ({
  palletNumber,
}: PalletComponentProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<PalletState>();
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const palletWorker = useWorker(createPalletWorker);
  const [pallet, setPallet] = useState<PalletState | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(palletNumber === "new");
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false);
  const [closeError, setCloseError] = useState<string | null>(null);
  const [closeSuccess, setCloseSuccess] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [showDespatchModal, setShowDespatchModal] = useState<boolean>(false);
  const [isDespatching, setIsDespatching] = useState<boolean>(false);
  const [despatchError, setDespatchError] = useState<string | null>(null);
  const [despatchSuccess, setDespatchSuccess] = useState<boolean>(false);
  const [isFetchError, setIsFetchError] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPallet = async () => {
      if (userAuthenticatedContext.token && palletNumber !== "new") {
        try {
          const result = await palletWorker.getPallet(
            palletNumber,
            userAuthenticatedContext.token
          );
          setPallet(result);
          setValue("clientId", result.clientId);
          setValue("subClientId", result.subClientId);
          setValue("location", result.location);
          setValue("load", result.load);
          setValue("palletType", result.palletType);
          setValue("stockType", result.stockType);
          setValue("comments", result.comments);
          setValue(
            "despatchTimestamp",
            result.despatchTimestamp
              ? formatDateTimeFromTimestamp(result.despatchTimestamp)
              : undefined
          );
        } catch (error) {
          console.error("Error fetching pallet:", error);
          setIsFetchError(true);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchPallet();
  }, [userAuthenticatedContext, palletWorker, palletNumber, setValue]);

  const onSubmit = async (data: Partial<PalletState>) => {
    setIsSaving(true);
    setSaveError(null);
    setSaveSuccess(false);

    try {
      if (userAuthenticatedContext.token) {
        if (palletNumber === "new") {
          const pallet = await palletWorker.createPallet(
            data as PalletState,
            userAuthenticatedContext.token
          );
          setPallet(pallet);
          navigate(`/pallet/${pallet.id}`);
        } else {
          await palletWorker.patchPallet(
            palletNumber,
            data,
            userAuthenticatedContext.token
          );
        }
        setSaveSuccess(true);
        setIsEditMode(false);
      }
    } catch (error) {
      setSaveError("Error saving pallet data");
      console.error("Error saving pallet data:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    if (pallet) {
      setValue("clientId", pallet.clientId);
      setValue("subClientId", pallet.subClientId);
      setValue("location", pallet.location);
      setValue("load", pallet.load);
      setValue("palletType", pallet.palletType);
      setValue("stockType", pallet.stockType);
      setValue("comments", pallet.comments);
      setValue(
        "despatchTimestamp",
        pallet.despatchTimestamp
          ? formatDateTimeFromTimestamp(pallet.despatchTimestamp)
          : undefined
      );
    }
  };

  const handleClosePallet = async () => {
    setIsClosing(true);
    setCloseError(null);
    setCloseSuccess(false);

    try {
      if (userAuthenticatedContext.token && pallet) {
        await palletWorker.closePallet(
          pallet.id,
          userAuthenticatedContext.token
        );
        setCloseSuccess(true);
        setPallet({ ...pallet, open: false });
        setShowCloseModal(false);
        setCloseError(null); // Reset close error
        setDespatchError(null); // Reset despatch error
        window.location.reload();
      }
    } catch (error) {
      setCloseError("Error closing pallet");
      console.error("Error closing pallet:", error);
    } finally {
      setIsClosing(false);
    }
  };

  const handleDespatchPallet = async (despatchType: string) => {
    setIsDespatching(true);
    setDespatchError(null);
    setDespatchSuccess(false);

    try {
      if (userAuthenticatedContext.token && pallet) {
        await palletWorker.despatchPallet(
          pallet.id,
          despatchType,
          userAuthenticatedContext.token
        );
        setDespatchSuccess(true);
        setPallet({
          ...pallet,
        });
        setShowDespatchModal(false);
        setDespatchError(null); // Reset despatch error
        setCloseError(null); // Reset close error
        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.status !== 500) {
        setDespatchError(error.response.data || "Error closing pallet");
      } else {
        setDespatchError("Error despatching pallet");
      }
    } finally {
      setIsDespatching(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  } else if (isFetchError) {
    return <Alert variant="danger">Error loading pallet data</Alert>;
  }

  return (
    <Container>
      <h1>
        {palletNumber === "new"
          ? "Create New Pallet"
          : isEditMode
          ? "Edit Pallet"
          : "View Pallet"}
      </h1>
      <div className="d-flex mb-3">
        <Button
          variant="primary"
          onClick={() => setIsEditMode(true)}
          disabled={isEditMode}
        >
          Edit
        </Button>
        <Button
          variant="danger"
          className="ms-2"
          onClick={() => navigate("/pallets")}
        >
          Back
        </Button>
        {pallet && (
          <>
            <Button
              variant="warning"
              className="ms-2"
              onClick={() => setShowCloseModal(true)}
              disabled={!pallet.open}
            >
              Close Pallet
            </Button>
            <Button
              variant="success"
              className="ms-2"
              onClick={() => setShowDespatchModal(true)}
              disabled={!!pallet.despatchTimestamp || !pallet.closedTimestamp}
            >
              Despatch Pallet
            </Button>
          </>
        )}
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Pallet Number</Form.Label>
              {palletNumber === "new" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-id">
                      Pallet number will be assigned when saved
                    </Tooltip>
                  }
                >
                  <Form.Control
                    {...register("id", { required: false })}
                    defaultValue={pallet?.id}
                    isInvalid={!!errors.id}
                    disabled={true}
                  />
                </OverlayTrigger>
              ) : (
                <Form.Control
                  {...register("id", { required: false })}
                  defaultValue={pallet?.id}
                  isInvalid={!!errors.id}
                  disabled={true}
                />
              )}
              <Form.Control.Feedback type="invalid">
                Pallet number is required
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Client ID</Form.Label>
              <Form.Control
                {...register("clientId", { required: true })}
                defaultValue={pallet?.clientId}
                isInvalid={!!errors.clientId}
                disabled={!isEditMode}
              />
              <Form.Control.Feedback type="invalid">
                Client ID is required
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Subclient ID</Form.Label>
              <Form.Control
                {...register("subClientId", { required: true })}
                defaultValue={pallet?.subClientId}
                isInvalid={!!errors.subClientId}
                disabled={!isEditMode}
              />
              <Form.Control.Feedback type="invalid">
                Subclient ID is required
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control
                {...register("location")}
                defaultValue={pallet?.location}
                disabled={!isEditMode}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Load</Form.Label>
              <Form.Control
                {...register("load")}
                defaultValue={pallet?.load}
                disabled={!isEditMode}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Pallet Type</Form.Label>
              <Controller
                name="palletType"
                control={control}
                defaultValue={pallet?.palletType}
                render={({ field }) => (
                  <Form.Select
                    {...field}
                    {...register("palletType")}
                    disabled={!isEditMode}
                  >
                    <option value="Retail">Retail</option>
                    <option value="Auction">Auction</option>
                  </Form.Select>
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Stock Type</Form.Label>
              <Controller
                name="stockType"
                control={control}
                defaultValue={pallet?.stockType}
                render={({ field }) => (
                  <Form.Select
                    {...field}
                    {...register("stockType")}
                    disabled={!isEditMode}
                  >
                    <option value="Mixed">Mixed</option>
                    <option value="Raw">Raw</option>
                    <option value="BER">BER</option>
                  </Form.Select>
                )}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...register("comments")}
                defaultValue={pallet?.comments}
                disabled={!isEditMode}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Closed</Form.Label>
              <Form.Control
                {...register("closedTimestamp")}
                defaultValue={
                  pallet?.closedTimestamp
                    ? formatDateTimeFromTimestamp(pallet?.closedTimestamp)
                    : ""
                }
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Despatched</Form.Label>
              <Form.Control
                {...register("despatchTimestamp")}
                defaultValue={
                  pallet?.despatchTimestamp
                    ? formatDateTimeFromTimestamp(pallet?.despatchTimestamp)
                    : " "
                }
                disabled={true}
              />
            </Form.Group>
          </Col>
        </Row>
        {isEditMode && (
          <>
            <Button variant="primary" type="submit" disabled={isSaving}>
              {isSaving ? "Saving..." : "Save"}
            </Button>
            <Button variant="secondary" className="ms-2" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        )}
        {saveError && (
          <Alert variant="danger" className="mt-3">
            {saveError}
          </Alert>
        )}
        {saveSuccess && (
          <Alert variant="success" className="mt-3">
            Pallet data saved successfully
          </Alert>
        )}
      </Form>

      <Modal show={showCloseModal} onHide={() => setShowCloseModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Close Pallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to close this pallet?</p>
          {closeError && (
            <Alert variant="danger" className="mt-3">
              {closeError}
            </Alert>
          )}
          {closeSuccess && (
            <Alert variant="success" className="mt-3">
              Pallet closed successfully
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowCloseModal(false);
              setCloseError(null);
            }}
            disabled={isClosing}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleClosePallet}
            disabled={isClosing}
          >
            {isClosing ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Closing...
              </>
            ) : (
              "Close Pallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <DespatchModal
        show={showDespatchModal}
        onHide={() => {
          setShowDespatchModal(false);
          setDespatchError(null);
        }}
        onConfirm={handleDespatchPallet}
        isDespatching={isDespatching}
        despatchError={despatchError}
        despatchSuccess={despatchSuccess}
      />
    </Container>
  );
};

export default PalletComponent;
