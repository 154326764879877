import { DetailedInformationComponentProps } from "./detailedInformation";

export type GradingConfigurationStepOutputSpecialKeys =
  | "clearcycleGrade" // Maps to internalGrade in InventoryState
  | "clearcyclePalletNumber" // Maps to pallet in InventoryState
  | "clearcycleGradingComment" // Maps to comments in InventoryState
  | "clearcycleDimensions" // Maps to dimensions in InventoryState
  | "clearcycleItemBoxCount"; // Maps to boxes in InventoryState

export interface GradingStepConfigurationCriteria {
  /**
   * isDefault is a special criteria that is used when no other criteria match.
   */
  isDefault?: boolean;
  /**
   * The client code to match.
   */
  clientCode?: string;
  /**
   * The sku to match.
   */
  sku?: string;
}

export interface GradingStepsConfiguration {
  /**
   * What criteria is used to select whether this grading configuration should be used.
   * At least one of the criteria must match for the configuration to be used.
   * `isDefault` is a special criteria that is used when no other criteria match.
   */
  gradeCriteria: GradingStepConfigurationCriteria;

  /**
   * Version of the configuration. This is used to identify the version of the configuration.
   * Should ascend with each change to the configuration.
   * This means that a configuration is effectively immutable.
   */
  configurationVersion: number;

  /**
   * Steps to be completed in the grading refurbishment process.
   */
  steps: GradingStep[];
}

export enum GradingStepType {
  CatalogueCheck = "Catalogue Check",
  /**
   * @deprecated Use Count instead
   */
  BoxCount = "Box Count",
  Count = "Count",
  PalletNumber = "Pallet Number",
  SingleChoice = "Choice",
  MultiChoice = "Multiple Choice",
  DetailedInformation = "Detailed Information",
  Dimensions = "Dimensions",
  SparesAndRepairs = "Spares and Repairs",
  Confirm = "Confirm",
  FreeText = "Free Text",
  Image = "Image",
  Complete = "Complete",
}

/**
 * Base interface for a refurbishment step.
 * Not to be used directly, use one of the specific step types.
 */
export interface GradingStepBase {
  stepType: GradingStepType;
  stepOutputKey: string;
}

export interface GradingStepBaseWithTextOverrides extends GradingStepBase {
  title: string;
  subtitle?: string;
  description?: string;
}

/**
 * Catalogue check step. This is used to check the catalogue for the item.
 */
export interface GradingStepCatalogueCheck
  extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.CatalogueCheck;
}

/**
 * Box count step. This is used to enter the number of boxes.
 * @deprecated Use {@link GradingStepCount} instead
 */
export interface GradingStepBoxCount
  extends Omit<GradingStepCount, "stepType" | "minimum"> {
  /**
   * Unused in box count specifically. Use {@link GradingStepCount} instead.
   */
  title: string;
  stepType: GradingStepType.BoxCount;
  variant: "inbound" | "outbound";
}

/**
 * Count step. This is used to enter a number count. Recommend a max of 10-12 limit, due to screen size constraints.
 * However this will work for any number.
 */
export interface GradingStepCount extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.Count;

  /**
   * Maximum number of items that can be entered.
   */
  limit?: number;
  /**
   * Minimum number of items that must be entered.
   * @default 1
   */
  minimum?: number;

  /**
   * The desired count for the step. Shows as an expected count (e.g. "Expected: 5").
   * Useful for a case where the catalogue states there should be 3 boxes but the user has 2.
   */
  desiredCount?: number;
}

export interface GradingChoiceOption {
  value: string;
  label: string;

  /**
   * Optional description to provide extra context to the choice.
   */
  description?: string;
}

/**
 * Choice step. This is used to select one option.
 */
export interface GradingStepChoice extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.SingleChoice;

  /**
   * Options to choose from. This step is used to select one option.
   * Each option has a value and a label.
   */
  options: GradingChoiceOption[];
}

/**
 * Multiple choice step. This is used to select multiple options.
 */
export interface GradingStepMultiChoice
  extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.MultiChoice;

  /**
   * Options to choose from. This step is used to select one or more options.
   * The value is what is stored in the output.
   */
  options: GradingChoiceOption[];

  /**
   * The minimum number of options that must be selected.
   * Not required - defaults to 1.
   */
  minSelections?: number;
}

/**
 * Used to enter detailed information about damages.
 */
export interface GradingStepDetailedInformation
  extends GradingStepBaseWithTextOverrides,
    DetailedInformationComponentProps {
  stepType: GradingStepType.DetailedInformation;
}

/**
 * Dimensions step. This is used to enter dimensions.
 */
export interface GradingStepDimensions
  extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.Dimensions;
}

/**
 * Spares and repairs step. This is used to enter information about spares and repairs.
 * For example, the user might enter that the device needs a new battery.
 */
export interface GradingStepSparesAndRepairs
  extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.SparesAndRepairs;
}

/**
 * Complete step. This is used to display a summary of the Grading process with a confirmation box.
 * This step should be the last step in the configuration.
 */
export interface GradingStepComplete extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.Complete;
}

/**
 * Free text step. This is used to display a title and subtitle, and enter free text.
 */
export interface GradingStepFreeText extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.FreeText;

  /**
   * The number of lines to display in the text area.
   * Defaults to 5.
   */
  lineCount?: number;

  /**
   * If the input text is required. If not provided, it's assumed it is not required.
   */
  isRequired?: boolean;
}

/**
 * Grading step to collect just an image
 */
export interface GradingStepImage extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.Image;

  /**
   * If the image is required. If not provided, it's assumed it is not required.
   */
  isRequired?: boolean;
}

/**
 * Confirmation step. This is used to confirm something.
 * For example, to confirm that the user has removed PII on the device.
 */
export interface GradingStepConfirm extends GradingStepBaseWithTextOverrides {
  stepType: GradingStepType.Confirm;
}

export type GradingStep =
  | GradingStepCatalogueCheck
  | GradingStepBoxCount
  | GradingStepCount
  | GradingStepChoice
  | GradingStepDetailedInformation
  | GradingStepDimensions
  | GradingStepSparesAndRepairs
  | GradingStepComplete
  | GradingStepChoice
  | GradingStepMultiChoice
  | GradingStepFreeText
  | GradingStepImage
  | GradingStepConfirm;

export interface Grade {
  value: string;
  description: string;
}
