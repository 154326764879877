import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CreatePalletLabelHTMLFromBase64 } from "../components/printing/generate-pallet-label-html";
import { useDebugMode } from "../hooks/useDebugMode";

export interface PalletLabelGeneratorPageProps {
  autoPrint?: boolean;
}

const PalletLabelGeneratorPage = ({
  autoPrint,
}: PalletLabelGeneratorPageProps) => {
  const location = useLocation();
  const [labelHTML, setLabelHTML] = useState<string>("");
  const [error, setError] = useState<string>();

  const [hasShownPrint, setHasShownPrint] = useState<boolean>(false);
  const debugMode = useDebugMode();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const base64Data = queryParams.get("data");

    if (!base64Data) {
      setError("No data provided in query string.");
      return;
    }

    CreatePalletLabelHTMLFromBase64(base64Data, debugMode)
      .then((html) => {
        setLabelHTML(html);
        if (autoPrint) {
          setTimeout(() => {
            if (hasShownPrint) return;
            window.print();
            setHasShownPrint(true);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error generating label HTML:", error);
        setError("Error generating label HTML.");
      });
  }, [location.search, autoPrint, hasShownPrint, debugMode]);

  return (
    <>
      {error ? (
        <>
          <h5>Error</h5>
          <p>{error}</p>
        </>
      ) : null}
      <div dangerouslySetInnerHTML={{ __html: labelHTML }} />
    </>
  );
};

export default PalletLabelGeneratorPage;
