import React, { useState } from "react";
import { PalletState } from "./types";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap-icons";
import { formatDateTimeFromTimestamp } from "../../helpers/datetime";
import { PalletFilters } from "../../workers/pallet";

export interface PalletTableProps {
  pallets: PalletState[];
  palletsLoaded: boolean;
  filters: PalletFilters;
  childToParentTableSort: (
    sortField: string,
    sortDirection: "asc" | "desc"
  ) => void;
}

const PalletTableComponent: React.FC<PalletTableProps> = ({
  pallets,
  filters,
  palletsLoaded,
  childToParentTableSort,
}) => {
  const [sortField, setSortField] = useState<string>(filters.sortField ?? "");
  const [sortDirection, setSortDirection] = useState<string>(
    filters.sortDirection
  );
  const navigate = useNavigate();

  const headers = [
    { name: "Images" },
    { name: "Pallet Number", sortKey: "id.keyword" },
    { name: "Client ID", sortKey: "clientId.keyword" },
    { name: "Client code", sortKey: "subClientId.keyword" },
    { name: "Location", sortKey: "location.keyword" },
    { name: "Pallet Type", sortKey: "palletType.keyword" },
    { name: "Stock Type", sortKey: "stockType.keyword" },
    { name: "Created at", sortKey: "createdAtTimestamp" },
    { name: "Last Updated", sortKey: "lastUpdatedTimestamp" },
    { name: "Open", sortKey: "open" },
    { name: "Actions" },
  ];

  const handleSort = (field: string) => {
    const newSortDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newSortDirection);
    childToParentTableSort(field, newSortDirection);
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {headers.map(({ name, sortKey }, i) => (
            <th
              className="sticky"
              key={i}
              onClick={() => sortKey && handleSort(sortKey)}
              style={{
                cursor: sortKey ? "pointer" : "default",
                opacity: sortKey === sortField ? 1 : 0.4,
              }}
            >
              {name}{" "}
              {sortField === sortKey
                ? sortDirection === "asc"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {pallets.map((pallet) => (
          <tr key={pallet.id}>
            <td>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                {pallet.imageUrls.length > 0 ? (
                  pallet.imageUrls && pallet.imageUrls.length > 0 ? (
                    pallet.imageUrls.map((imageUrl, i) => (
                      <picture key={i}>
                        <source srcSet={imageUrl}></source>
                        <img
                          style={{ maxWidth: `90px`, margin: "0.25em" }}
                          alt={`View ${i} of pallet number ${pallet.id}`}
                        ></img>
                      </picture>
                    ))
                  ) : (
                    <Image color="lightgrey" />
                  )
                ) : null}
              </div>
            </td>
            <td>{pallet.id}</td>
            <td>{pallet.clientId}</td>
            <td>{pallet.subClientId}</td>
            <td>{pallet.location}</td>
            <td>{pallet.palletType}</td>
            <td>{pallet.stockType}</td>
            <td>{formatDateTimeFromTimestamp(pallet.createdAtTimestamp)}</td>
            <td>{formatDateTimeFromTimestamp(pallet.lastUpdatedTimestamp)}</td>
            <td>{pallet.open ? "Yes" : "No"}</td>
            <td>
              <Button
                onClick={() => {
                  navigate(`/pallet/${pallet.id}`);
                }}
                size="sm"
              >
                View
              </Button>

              {/* TODO: navigate to inventory page filtered on pallet number */}
              <Button
                onClick={() => {
                  navigate(`/inventory?palletNumber=${pallet.id}`);
                }}
                className="ms-1"
                size="sm"
                variant="info"
                disabled={true}
              >
                Items
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PalletTableComponent;
