import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

interface DespatchModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: (despatchType: string) => void;
  isDespatching: boolean;
  despatchError: string | null;
  despatchSuccess: boolean;
}

const DespatchModal: React.FC<DespatchModalProps> = ({
  show,
  onHide,
  onConfirm,
  isDespatching,
  despatchError,
  despatchSuccess,
}) => {
  const [despatchType, setDespatchType] = React.useState<string>("Retail");

  const handleConfirm = () => {
    onConfirm(despatchType);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Despatch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Despatch Type</Form.Label>
          <Form.Select
            value={despatchType}
            onChange={(e) => setDespatchType(e.target.value)}
          >
            <option value="Retail">Retail</option>
            <option value="Auction">Auction</option>
            <option value="BTS">BTS</option>
          </Form.Select>
        </Form.Group>
        {despatchError && (
          <Alert variant="danger" className="mt-3">
            {`${despatchError}`}
          </Alert>
        )}
        {despatchSuccess && (
          <Alert variant="success" className="mt-3">
            Pallet despatched successfully
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={isDespatching}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleConfirm}
          disabled={isDespatching}
        >
          {isDespatching ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Despatching...
            </>
          ) : (
            "Confirm"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DespatchModal;
