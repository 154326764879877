import { createWorkerFactory } from "@shopify/react-web-worker";
import { CatalogItem } from "../../../external";
import {
  GradingStepsConfiguration,
  GradingStepType,
} from "../../../external/inventory-component/configuration/types";

const createGradingWorker = createWorkerFactory(
  () => import("../../../workers/grading")
);

/**
 * Retrieves the correct grading steps configuration.
 * @param userToken The user token to retrieve the grading configuration
 * @param catalogItem The catalog item
 * @returns the relevant configuration
 */
export const getGradingAndRefurbStepsConfiguration = async (
  userToken: string,
  catalogItem: CatalogItem
): Promise<GradingStepsConfiguration> => {
  const gradingWorker = createGradingWorker();
  return (
    await gradingWorker.getConfiguration(
      userToken,
      catalogItem.clientId,
      catalogItem.itemId
    )
  ).configuration;
};

export const DefaultTestRefurbStepsConfiguration = (
  catalogItem: CatalogItem
): GradingStepsConfiguration => ({
  configurationVersion: 3,
  gradeCriteria: {
    isDefault: true,
  },
  steps: [
    {
      stepOutputKey: "catalogueCheck",
      stepType: GradingStepType.CatalogueCheck,
      title: "Catalogue Check",
    },
    {
      stepOutputKey: "inboundBoxCount",
      variant: "inbound",
      stepType: GradingStepType.BoxCount,
      title: "Inbound Box Count",
    },
    {
      stepOutputKey: "causeOfReturn",
      stepType: GradingStepType.SingleChoice,
      title: "Cause of Return",
      subtitle: "Please provide the likely cause of return",
      options: [
        { value: "transitDamage", label: "Transit Damage" },
        { value: "customerReturn", label: "Customer Return" },
        { value: "manufacturingDefect", label: "Manufacturing Defect" },
        { value: "customerError", label: "Customer Error" },
        { value: "incomplete", label: "Incomplete" },
        { value: "refurbishment", label: "Refurbishment" },
        { value: "other", label: "Other" },
      ],
    },
    {
      stepOutputKey: "clearcycleGrade",
      stepType: GradingStepType.SingleChoice,
      title: "Grade",
      options: [
        {
          value: "A+",
          label: "A+",
          description: "Mint condition, exactly as new but opened box.",
        },
        {
          value: "A",
          label: "A",
          description:
            "Item as good as new, perhaps previously assembled with minimal signs of prior use.",
        },
        {
          value: "B+",
          label: "B+",
          description:
            "Item used once or twice, but in excellent condition. Up to 1-2 light scuffs, marks, scratches or dents.",
        },
        {
          value: "B",
          label: "B",
          description:
            "Item used previously. Very good condition with 1-2 moderate scuffs, marks, scratches or dents.",
        },
        {
          value: "AC",
          label: "AC",
          description:
            "Equivalent to A grade, but comments are required. For example, a sofa that is complete but has a scatter cushion that doesn't colour match.",
        },
        {
          value: "BC",
          label: "BC",
          description:
            "Equivalent to B grade, but comments are required. For example, there are parts missing that do not affect the functionality.",
        },
        {
          value: "C1",
          label: "C1",
          description:
            "Good stock but not identifiable, so will go to auction.",
        },
        {
          value: "C2",
          label: "C2",
          description:
            "Needs work to be retail ready - good for hobbyist to repair or upcycle.",
        },
        {
          value: "C3",
          label: "C3",
          description:
            "Needs a lot of work to bring to retail ready. Good for a commercial outfit to repair or reuse.",
        },
        {
          value: "BER",
          label: "BER",
          description:
            "Beyond economical repair. Item is incomplete with no chance of spares, or requires repairs but would take too long to do those repairs.",
        },
      ],
    },
    {
      stepType: GradingStepType.FreeText,
      stepOutputKey: "clearcycleGradingComment",
      title: "Grading/Refurb comments",
      subtitle:
        "Please enter any comments relating to grading or refurbishment of this item.",
    },
    {
      stepType: GradingStepType.FreeText,
      stepOutputKey: "clearcyclePalletNumber",
      title: "Pallet number",
      lineCount: 1,
      subtitle: "Please enter pallet number.",
      isRequired: true,
    },
    {
      stepOutputKey: "clearcycleItemBoxCount",
      variant: "outbound",
      stepType: GradingStepType.BoxCount,
      title: "Outbound Box Count",
    },
    {
      stepOutputKey: "clearcycleDimensions",
      title: "Dimensions",
      stepType: GradingStepType.Dimensions,
    },
    {
      stepType: GradingStepType.Confirm,
      stepOutputKey: "piiRemovalConfirmed",
      title: "Confirm PII Removal",
      description:
        "By checking this box, you are confirming that you have physically inspected the outgoing boxes and removed or permanently covered any personally identifiable information such as names, addresses, phone numbers etc.",
    },
    {
      stepOutputKey: "image1",
      title: "Image test 1",
      stepType: GradingStepType.Image,
    },
    {
      stepOutputKey: "complete",
      stepType: GradingStepType.Complete,
      description:
        "Please tick to confirm that the information for the grade is correct.",
      title: "Complete",
    },
  ],
});
