import { ItemLocationScanner } from "../components/location/item-scanner";

const ItemLocationScanPage = () => {
  return (
    <>
      <ItemLocationScanner />
    </>
  );
};
export default ItemLocationScanPage;
