import { CSSProperties, useCallback, useRef, useState } from "react";
import { Result } from "react-zxing";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import BarcodeScanner from "../barcode-scanner";

export const ItemLocationScanner = () => {
  const [showBarcodeScanner, setShowBarcodeScanner] = useState<boolean>(false);
  const [itemValue, setItemValue] = useState<string>();
  const [locationValue, setLocationValue] = useState<string>();

  const onItemBarcodeDetected = useCallback(
    (barcode: Result) => {
      const v = barcode.getText();
      setItemValue(v);
      setShowBarcodeScanner(false);
    },
    [setItemValue, setShowBarcodeScanner]
  );
  const onLocationBarcodeDetected = useCallback(
    (barcode: Result) => {
      const v = barcode.getText();
      setLocationValue(v);
      setShowBarcodeScanner(false);
    },
    [setLocationValue, setShowBarcodeScanner]
  );

  const itemInputRef = useRef<HTMLInputElement>(null);
  const locationInputRef = useRef<HTMLInputElement>(null);
  const scanInputStyle: CSSProperties = {
    fontSize: "1.5em",
    fontFamily: "Menlo, monospace, sans-serif",
    textAlign: "center",
    maxWidth: "800px",
  };

  const storeValues = () => {
    alert("This is where we would store values in the database.");
  };

  return (
    <Container>
      <h3>Location Scanner</h3>
      <Row>
        <Col
          style={{
            ...scanInputStyle,
            marginBottom: "0.5em",
          }}
        >
          <Button
            variant="secondary"
            size="lg"
            onClick={() => setShowBarcodeScanner(!showBarcodeScanner)}
          >
            {!showBarcodeScanner ? "Use" : "Hide"} Camera Barcode Scanner
          </Button>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <b>Item</b>
          <Form.Control
            style={scanInputStyle}
            autoFocus
            inputMode="none" // Prevents the keyboard from showing up on mobile. We might not actually want this.
            type="text"
            name="type"
            placeholder="PMID or Pallet"
            ref={itemInputRef}
            value={itemValue}
            onChange={(e) => setItemValue(e.target.value)}
          />
        </Col>
      </Row>
      {showBarcodeScanner ? (
        <Row>
          <Col>
            <BarcodeScanner onBarcodeDetected={onItemBarcodeDetected} />
          </Col>
        </Row>
      ) : null}
      {itemValue ? (
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <b>Location</b>
            <Form.Control
              style={scanInputStyle}
              autoFocus
              inputMode="none" // Prevents the keyboard from showing up on mobile. We might not actually want this.
              type="text"
              name="type"
              placeholder="Pallet or Warehouse Location"
              ref={locationInputRef}
              value={locationValue}
              onChange={(e) => setLocationValue(e.target.value)}
            />
          </Col>
        </Row>
      ) : null}
      {showBarcodeScanner && itemValue ? (
        <Row>
          <Col>
            <BarcodeScanner onBarcodeDetected={onLocationBarcodeDetected} />
          </Col>
        </Row>
      ) : null}
      <br />
      {itemValue && locationValue ? (
        <>
          <Row>
            <Col>
              Store item <br />
              <b>{itemValue}</b>
              <br /> in location <br />
              <b>{locationValue}</b>?
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                ...scanInputStyle,
                marginTop: "1em",
              }}
            >
              <Button size="lg" onClick={storeValues}>
                Confirm Location
              </Button>
            </Col>
          </Row>
        </>
      ) : null}
    </Container>
  );
};
