import { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserAuthenticatedContext } from "../../components/profile";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/loading";
import { Client, InventoryItem, Meta } from "../../external";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
import InventoryTableComponent from "../../components/inventory/inventory-table";
import DynamicSearchComponent, {
  SearchFieldConfig,
} from "../../components/dynamic-search-component";
import { InventoryFilters } from "../../workers/inventory";
import PaginationComponent from "../../components/pagination";
import { getOpenSearchItems } from "../../helpers/opensearch-helpers";

const createClientsWorker = createWorkerFactory(
  () => import("../../workers/clients")
);

export type OpenSearchQuery = {
  count: number; // Number of items to return
  page: number; // Pagination page number
  query: {
    bool: {
      should: Array<{
        match?: { [key: string]: any };
        bool?: {
          must_not: {
            exists: {
              field: string;
            };
          };
        };
      }>;
      must: Array<{
        match: { [key: string]: any };
        terms?: { [key: string]: any[] }; // To support multiple match conditions
      }>;
    };
  };
  sort: Array<{
    [key: string]: {
      order: string; // Sort order, could be ascending or descending
    };
  }>;
};

const InventoryPage = (): JSX.Element => {
  const navigate = useNavigate();
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const clientsWorker = useWorker(createClientsWorker);

  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [inventoryItemsLoaded, setInventoryItemsLoaded] =
    useState<boolean>(false);
  const [showFilters, setShowFilters] = useState(false);

  const [sortField, setSortField] = useState("createdAtTimestamp");
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">("desc");

  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [meta, setMeta] = useState<Meta>(() => ({
    next: null,
    previous: null,
    current: 1,
    totalPages: 1,
    maxPerPage: 50,
    totalResults: 0,
  }));

  const [filters, setFilters] = useState<InventoryFilters>(() => ({
    count: 25,
    page: 1,
    sortField,
    sortDirection,
  }));

  const [clients, setClients] = useState<Client[]>([]);
  const [clientsLoaded, setClientsLoaded] = useState<boolean>(false);

  const handleCloseFilters = () => setShowFilters(false);
  const handleShowFilters = () => setShowFilters(true);

  const handleTableSort = useCallback(
    (sortField: string, sortDirection: "asc" | "desc") => {
      setSortField(sortField);
      setSortDirection(sortDirection);
      setFilters((prevFilters) => ({
        ...prevFilters,
        sortField: sortField,
        sortDirection: sortDirection,
      }));
    },
    []
  );

  const searchFields: SearchFieldConfig[] = [
    { key: "text", label: "Search", type: "text" },
    { key: "id", label: "ID", type: "text" },
    { key: "clientItemId", label: "Client SKU", type: "text" },
    { key: "description", label: "Name", type: "text" },
    { key: "loadReferenceId", label: "Load Reference", type: "text" },
    { key: "expectedArrivalDate", label: "Arrival Date", type: "date" },
  ];

  const getInventoryItems = useCallback(
    async (authToken: string, filters: InventoryFilters) => {
      const updatedFilters = {
        ...filters,
        loadReferenceId: filters.loadReferenceId ?? undefined,
      };

      try {
        return await getOpenSearchItems<InventoryItem>(
          authToken,
          updatedFilters,
          "INVENTORY"
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 403) {
          setIsError(true);
          setError(
            "Authentication error: You do not have permission to access this resource."
          );
        } else {
          setIsError(true);
          setError("An error occurred while fetching inventory items.");
        }
        throw error;
      }
    },
    []
  );

  const defaultCurrentPage = 1;
  const handleCount = (count: number) => {
    setFilters({ ...filters, count: count, page: defaultCurrentPage });
  };
  const handleCurrentPage = (pageNumber: number) => {
    setFilters({ ...filters, page: pageNumber });
  };

  useEffect(() => {
    if (userAuthenticatedContext.token) {
      (async () => {
        const clients = await clientsWorker.getClients(
          userAuthenticatedContext.token!
        );
        setClients(clients);
        setClientsLoaded(true);
      })();
    }
  }, [userAuthenticatedContext, clientsWorker]);

  if (
    !inventoryItems ||
    !clientsLoaded ||
    !userAuthenticatedContext ||
    !userAuthenticatedContext.token
  ) {
    return <Loading />;
  }

  return (
    <Container style={{ width: "95%", minWidth: "95%" }}>
      <Row>
        <Col lg="3">
          <Offcanvas
            show={showFilters}
            placement="start"
            responsive="lg"
            onHide={handleCloseFilters}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ justifyContent: "left" }}>
              <DynamicSearchComponent
                clients={clients}
                isLoaded={true}
                setItems={setInventoryItems}
                setItemsLoaded={setInventoryItemsLoaded}
                searchFields={searchFields}
                filters={filters}
                setFilters={setFilters}
                onSearch={getInventoryItems}
                sortField={sortField}
                sortDirection={sortDirection}
                setMeta={setMeta}
                setIsError={setIsError}
                setError={setError}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
        <Col
          lg="9"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
        >
          <Row>
            <Col>
              <h2 style={{ textAlign: "left" }}>Inventory</h2>
            </Col>
          </Row>
          <Row>
            <ButtonGroup>
              <Row>
                <Col xs="auto">
                  <Button
                    variant="primary"
                    onClick={() => navigate("/inventory/item")}
                    className="btn-block"
                  >
                    Scan Item
                  </Button>
                </Col>
                <Col xs="auto" className="d-lg-none" style={{ float: "left" }}>
                  <Button
                    variant="success"
                    onClick={handleShowFilters}
                    className="btn-block"
                  >
                    Show Filters
                  </Button>
                </Col>
              </Row>
            </ButtonGroup>
          </Row>
          {inventoryItemsLoaded ? (
            <InventoryTableComponent
              inventoryItems={inventoryItems}
              inventoryItemsLoaded={inventoryItemsLoaded}
              filters={filters}
              childToParentTableSort={(sortField, sortDirection) => {
                handleTableSort(sortField, sortDirection);
              }}
              isError={isError}
              error={error}
            />
          ) : (
            <Loading />
          )}
          <Row>
            <PaginationComponent
              meta={meta}
              itemsLoaded={inventoryItemsLoaded}
              childToParentPages={handleCurrentPage}
              childToParentCount={handleCount}
            ></PaginationComponent>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(InventoryPage, {
  onRedirecting: () => <Loading />,
});
