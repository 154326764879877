import { CatalogItem, InventoryItem } from "../../external";
import QRCode from "qrcode";
import { retrieveTemplate } from "./retrieveTemplate";
import { LabelGenerationOptions } from "./types";
import Handlebars from "handlebars";

export interface PalletLabelData {
  itemRows: InventoryItemWithCatalogInfo[];
  palletText: string;
  palletNumber: string;
}

export interface InventoryItemWithCatalogInfo extends InventoryItem {
  catalogInfo: CatalogItem;
}

export interface InventoryItemWithQRCode extends InventoryItem {
  qrCodeContents: string;
}

export const CreatePalletLabelHTML = async ({
  data,
  width = 150,
  imageType = "image/png",
}: LabelGenerationOptions<PalletLabelData>) => {
  const template = await retrieveTemplate("/templates/pallet-label.hbs");
  const generateQR = async (text: string) =>
    await QRCode.toDataURL(text, {
      errorCorrectionLevel: "Q",
      width,
      type: imageType,
    });

  let palletNumberQRContents;
  if (data.palletNumber) {
    palletNumberQRContents = await generateQR(data.palletNumber);
  }

  let renderCircleId = false;
  if (data.itemRows.some((item) => !!item.id)) {
    renderCircleId = true;
  }
  let renderPMID = false;
  if (data.itemRows.some((item) => !!item.legacyPMID)) {
    renderPMID = true;
  }

  let newItemRows = await Promise.all(
    data.itemRows.map(async (item) => {
      let qrCodeItemId;
      if (item.id) {
        qrCodeItemId = await generateQR(item.id);
      }
      let qrCodeContentsPMID;
      if (item.legacyPMID) {
        qrCodeContentsPMID = await generateQR(item.legacyPMID);
      }
      const { catalogInfo, ...itemFields } = item;
      return {
        ...itemFields,
        sku: catalogInfo.clientItemId,
        productListingTitle: catalogInfo.name,
        qrCodeContentsID: qrCodeItemId,
        qrCodeContentsPMID,
        renderCircleId,
        renderPMID,
      };
    })
  );

  const compiledTemplate = Handlebars.compile(template);
  const renderedTemplate = compiledTemplate({
    ...data,
    palletNumberQRContents,
    itemRows: newItemRows,
    renderPMID,
    renderCircleId,
  });
  return renderedTemplate;
};

export const CreatePalletLabelHTMLFromBase64 = async (
  base64String: string,
  debug: boolean = false
) => {
  try {
    if (debug) {
      console.debug("Decoding base64 JSON data:", base64String);
    }
    const jsonString = atob(base64String);
    const data = JSON.parse(jsonString);
    return await CreatePalletLabelHTML({ data });
  } catch (error) {
    console.error("Error decoding base64 JSON data:", error);
    throw new Error("Error decoding base64 JSON data.");
  }
};
