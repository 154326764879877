import { useNavigate } from "react-router";
import { InventoryItem } from "../../external/inventory-component/types";

export interface InventoryRowProps {
  inventoryItem: InventoryItem;
}

const InventoryRowComponent = (props: InventoryRowProps): JSX.Element => {
  const navigate = useNavigate();
  const { inventoryItem } = props;

  const handleNavigation = () => {
    try {
      navigate(`/inventory/item?itemId=${inventoryItem.id}`);
    } catch (error) {
      if (error instanceof Error && error.message.includes("403")) {
        alert("Authentication error: You do not have permission to access this resource.");
      } else {
        console.error("Navigation error:", error);
      }
    }
  };

  return (
    <tr onClick={handleNavigation}>
      <td>
        <code>{inventoryItem.id}</code>
      </td>
      <td>{inventoryItem.clientId}</td>
      <td>{inventoryItem.subClientId}</td>
      <td>{inventoryItem.loadReferenceId}</td>
      <td>{inventoryItem.clientItemId}</td>
      <td
        dangerouslySetInnerHTML={{ __html: inventoryItem.description ?? "" }}
      ></td>
      <td>
        {inventoryItem.expectedArrivalDate
          ? new Date(inventoryItem.expectedArrivalDate).toDateString()
          : ""}
      </td>
    </tr>
  );
};

export default InventoryRowComponent;
